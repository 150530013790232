<template>
  <v-layout style="height: 100%">
    <v-container
      grid-list-xl
      fluid
      column
      style="height: 100%; display: flex; flex-direction: column;"
    >
      <v-card color="blue" v-if="firstMessage" style="flex-grow:0;">
        <v-card-text>
          <v-icon class="pa-2">mdi-information-outline</v-icon>
          Hey, it doesn't look like you two have spoken before! Say hi and solve
          his problem.
        </v-card-text>
      </v-card>
      <v-card v-if="conversation && user && user2">
        <v-card-title style="display: flex; justify-content: space-between;">
          <div>
            <label v-if="user.type == 'Organization'">
              {{ user.name }} ({{ user.type }})
            </label>
            <label v-else>
              {{
                user.first_name + (user.last_name ? " " + user.last_name : "")
              }}
              ({{ user.type }})
            </label>
            ,
            <label v-if="user2.type == 'Organization'">
              {{ user2.name }} ({{ user2.type }})
            </label>
            <label v-else>
              {{
                user2.first_name +
                  (user2.last_name ? " " + user2.last_name : "")
              }}
              ({{ user2.type }})
            </label>
          </div>
          <v-menu offset-y rounded="lg">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon>
                <v-icon color="#482684">
                  mdi-dots-horizontal
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in menus"
                v-on:change="onSelectedMenu(index)"
                :key="index"
                link
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-title>
      </v-card>
      <v-card v-else-if="conversation && user">
        <v-card-title style="display: flex; justify-content: space-between;">
          <label v-if="user.type == 'Organization'">
            {{ user.name }}
          </label>
          <label v-else>
            {{ user.first_name + (user.last_name ? " " + user.last_name : "") }}
          </label>
          <v-menu offset-y rounded="lg">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon>
                <v-icon color="#482684">
                  mdi-dots-horizontal
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in menus"
                v-on:change="onSelectedMenu(index)"
                :key="index"
                link
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-title>
        <v-card-text>{{ user.type }}</v-card-text>
      </v-card>
      <v-card v-else-if="conversation">
        <v-card-title style="display: flex; justify-content: space-between;">
          <label>
            Unknown user
          </label>
          <v-menu offset-y rounded="lg">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon>
                <v-icon color="#482684">
                  mdi-dots-horizontal
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in menus"
                v-on:change="onSelectedMenu(index)"
                :key="index"
                link
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-title>
      </v-card>
      <div
        id="chat-content"
        ref="chatContent"
        style="height: 100%;padding-top:20px; padding-bottom: 90px;flex-grow:1; overflow-y: scroll;"
      >
        <v-layout
          v-for="(msg, index) in messages"
          :key="index"
          row
          class="mx-3"
          :ref="index == messages.length - 1 ? 'lastMessage' : ''"
        >
          <v-flex
            xs6
            sm4
            :offset-xs6="msg.sender === profile._id"
            :offset-sm8="msg.sender === profile._id"
          >
            <div style="color: gray; font-size: 12px; margin-bottom: 5px;">
              {{ formatDate(msg.date) }}
            </div>
            <v-card
              class="msg text-block"
              :color="msg.sender !== profile._id ? 'white' : 'blue'"
            >
              <v-card-text
                :class="
                  msg.sender !== profile._id ? 'black--text' : 'white--text'
                "
              >
                {{ msg.text }}
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </div>
    </v-container>
    <v-footer absolute>
      <v-layout row align-center class="pa-4">
        <v-flex style="flex-grow:1; max-width: 100%">
          <v-textarea
            v-model="message"
            @keyup.enter="sendMessageButton()"
            label="Type a message..."
            rows="1"
            auto-grow
          />
        </v-flex>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="blue"
              text
              @click="sendMessageButton()"
              class="ml-5"
              v-bind="attrs"
              v-on="on"
            >
              <span>send</span>
              <v-icon class="ml-2">mdi-send</v-icon>
            </v-btn>
          </template>
          <span>Enter to send</span>
        </v-tooltip>
      </v-layout>
    </v-footer>
    <confirm-delete-dialog
      :deleteDialog="deleteDialog"
      :onClose="onCloseDialog"
      :onConfirm="onConfirmDeleteConversation"
    />
    <partner-chat-rooms
      :dialog="chatRoomsDialog"
      :onCloseDialog="onCloseDialog"
      :_id="user._id"
      :type="user.type"
      v-if="user && chatRoomsDialog"
    />
  </v-layout>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import io from "socket.io-client";
import ConfirmDeleteDialog from "../../components/ConfirmDeleteDialog.vue";
import PartnerChatRooms from "./PartnerChatRooms.vue";
var socket = io.connect(process.env.VUE_APP_SOCKET_URL);
import dateFormat from "dateformat";

export default {
  components: { ConfirmDeleteDialog, PartnerChatRooms },
  data() {
    return {
      firstMessage: false,
      messages: [],
      message: "",
      conversation: null,
      menus: [{ title: "Remove conversation" }],
      deleteDialog: false,
      chatRoomsDialog: false,
      user: null,
      user2: null,
    };
  },
  methods: {
    ...mapActions({
      getChat: "chat/getChat",
      getConversation: "chat/getConversation",
      deleteConversation: "chat/deleteConversation",
      sendMessage: "chat/sendMessage",
      getClient: "client/getClient",
      getPartner: "partner/getPartner",
      getFreezer: "freezer/getFreezer",
      getUser: "user/getUser",
      getOrganization: "partner/getOrganization",
    }),
    sendMessageButton() {
      if (this.conversation && this.message) {
        this.sendMessage({
          conversation_id: this.$route.params.id,
          content_type: "text",
          text: this.message,
        }).then(() => {
          this.message = "";
        });
      }
    },
    onScroll(e) {
      console.log(e.target);
    },
    onRefresh() {
      this.loading = true;
      if (this.$route.params.id == "inbox") return;
      this.getConversation(this.$route.params.id).then((conversation) => {
        this.conversation = conversation;
        this.getParticipants();
      });
      this.getChat(this.$route.params.id)
        .then((messages) => {
          this.loading = false;
          this.messages = messages;
          if (this.messages.length != 0) {
            setTimeout(() => {
              if (this.$refs.lastMessage[0])
                this.$vuetify.goTo(
                  this.$refs.lastMessage[0],
                  {
                    container: this.$refs.chatContent,
                  },
                  500
                );
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            console.log(error);
          }
        });
      socket.removeAllListeners();
      socket.on(`newMessage-${this.$route.params.id}`, (data) => {
        if (data.conversation_id == this.$route.params.id) {
          this.messages.push(data);
          setTimeout(() => {
            if (this.$refs.lastMessage[0]) {
              this.$vuetify.goTo(
                this.$refs.lastMessage[0],
                {
                  container: this.$refs.chatContent,
                },
                300
              );
            }
          });
        }
      });
    },
    onSelectedMenu(index) {
      if (this.menus[index].title == "Remove conversation") {
        console.log("delete");
        this.deleteDialog = true;
      } else if (this.menus[index].title == "Chat rooms") {
        this.chatRoomsDialog = true;
      }
    },
    onConfirmDeleteConversation() {
      this.deleteDialog = false;
      this.deleteConversation({ _id: this.conversation._id });
      this.$router.back();
    },
    onCloseDialog() {
      this.deleteDialog = false;
      this.chatRoomsDialog = false;
    },
    getParticipants() {
      this.user = null;
      this.user2 = null;
      if (this.conversation && this.conversation.participants.length >= 2) {
        for (var i = 0; i < this.conversation.participants.length; i++) {
          var p = this.conversation.participants[i];
          if (p._id != this.profile._id || p.type != this.profileType) {
            if (this.profileType == "Admin" && p.type == "Admin") continue;
            if (p.type == "Client" && p._id != this.profile._id) {
              this.getClient(p._id)
                .then((res) => {
                  if (!this.user) {
                    this.user = res;
                    this.user.type = "Client";
                  } else {
                    this.user2 = res;
                    this.user2.type = "Client";
                    this.menus = [{ title: "Remove conversation" }];
                  }
                })
                .catch((error) => {
                  console.log(error.response.data.message);
                });
            } else if (p.type == "Partner" && p._id != this.profile._id) {
              console.log(p._id, this.profile._id);
              this.getPartner(p._id)
                .then((res) => {
                  if (!this.user) {
                    this.user = res;
                    this.user.type = "Partner";
                  } else {
                    this.user2 = res;
                    this.user2.type = "Partner";
                    this.menus = [{ title: "Remove conversation" }];
                  }
                })
                .catch((error) => {
                  this.user = null;
                  console.log(error.response.data.message);
                });
            } else if (p.type == "Organization") {
              this.getOrganization({ _id: p._id })
                .then((res) => {
                  if (!this.user) {
                    this.user = res;
                    this.user.type = "Organization";
                  } else {
                    this.user2 = res;
                    this.user2.type = "Organization";
                    this.menus = [{ title: "Remove conversation" }];
                  }
                })
                .catch((error) => {
                  this.user = null;
                  console.log(error.response.data.message);
                });
            } else if (p.type == "Freezer") {
              this.getFreezer(p._id)
                .then((res) => {
                  if (!this.user) {
                    this.user = res;
                    this.user.type = "Freezer";
                  } else {
                    this.user2 = res;
                    this.user2.type = "Freezer";
                    this.menus = [{ title: "Remove conversation" }];
                  }
                })
                .catch((error) => {
                  console.log(error.response.data.message);
                });
            } else if (p.type == "User") {
              this.getUser(p._id)
                .then((res) => {
                  if (!this.user) {
                    this.user = res;
                    this.user.type = "User";
                  } else {
                    this.user2 = res;
                    this.user2.type = "User";
                    this.menus = [{ title: "Remove conversation" }];
                  }
                })
                .catch((error) => {
                  console.log(error.response.data.message);
                });
            } else if (p.type == "Admin") {
              if (!this.user) {
                this.user = {
                  first_name: "Support",
                  type: "Admin",
                };
              }
            }
          }
        }
      }
    },
    formatDate(dateStr) {
      var today = new Date();
      var date = new Date(dateStr);
      var todayFormat = "hh:MM TT";
      var weekFormat = "ddd hh:MM TT";
      var dayFormat = "mm/dd hh:MM TT";
      var yearFormat = "mm/dd/yyyy hh:MM TT";
      var diffTime = today.getTime() - date.getTime();
      var diffDay = diffTime / (1000 * 3600 * 24);
      if (diffDay < 1) {
        return dateFormat(
          this.convertTZ(date, this.profile.timezone),
          todayFormat
        );
      } else if (diffDay < 7) {
        return dateFormat(
          this.convertTZ(date, this.profile.timezone),
          weekFormat
        );
      } else {
        if (
          dateFormat(this.convertTZ(today, this.profile.timezone), "yyyy") ==
          dateFormat(this.convertTZ(date, this.profile.timezone), "yyyy")
        ) {
          return dateFormat(
            this.convertTZ(date, this.profile.timezone),
            dayFormat
          );
        } else {
          return dateFormat(
            this.convertTZ(date, this.profile.timezone),
            yearFormat
          );
        }
      }
    },
    convertTZ(date, tzString) {
      if (!date) return "";
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: tzString ? tzString : "America/New_York",
          }
        )
      );
    },
  },
  computed: {
    ...mapGetters({
      profile: "auth/getProfile",
      profileType: "auth/getType",
    }),
  },
  created() {
    this.onRefresh();
    if (this.profileType == "Admin")
      this.menus = [{ title: "Remove conversation" }, { title: "Chat rooms" }];

    if (this.$vuetify.breakpoint.smAndDown) {
      this.$router.push({
        name: "mchat-details",
        params: { id: this.$route.params.id },
      });
    }
  },
  watch: {
    "$route.params.id": function() {
      this.onRefresh();
      if (this.$vuetify.breakpoint.smAndDown) {
        this.$router.push({
          name: "mchat-details",
          params: { id: this.$route.params.id },
        });
      }
    },
  },
};
</script>
<style>
.text-block {
  white-space: pre-line;
  overflow: hidden;
  display: block;
}
</style>
