<template>
  <v-dialog v-model="dialog" persistent max-width="750px">
    <v-card>
      <v-card-title
        style="display:flex; flex-direction: row; justify-content:space-between;"
      >
        <span class="headline">Chat rooms</span>
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        Employees who had access to this client data.
      </v-card-text>
      <v-card-text class="pb-0 d-flex flex-column">
        <div
          v-for="item in items"
          :key="item._id"
          class="d-flex justify-between w-100 hover-item"
          @click="goToChatRoom(item)"
        >
          <div class="d-flex">
            <div
              v-for="participant in item.participants"
              :key="participant._id"
              class="d-flex"
            >
              <editable-avatar
                :image="participant.image"
                :size="40"
                :editable="false"
                class="mr-4"
              />
              <div class="d-flex flex-column">
                <div>
                  {{
                    participant.name
                      ? participant.name
                      : participant.first_name +
                        (participant.last_name
                          ? " " + participant.last_name
                          : "")
                  }}
                </div>
                <div>
                  {{
                    participant.email ? participant.email : participant.phone
                  }}
                </div>
              </div>
            </div>
          </div>
          <div>
            {{ formatDate(item.date) }}
          </div>
        </div>
      </v-card-text>
      <v-alert dense type="error" v-if="error" class="mx-4">
        {{ error }}
      </v-alert>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="onClose">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
import dateFormat from "dateformat";
import EditableAvatar from "../../components/core/EditableAvatar.vue";

export default {
  components: { EditableAvatar },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    _id: String,
    type: String,
    onCloseDialog: {
      type: Function,
    },
  },
  data() {
    return {
      error: null,
      loading: false,
      headers: [
        { text: "", value: "image", sortable: false },
        { text: "Description", value: "text" },
        { text: "Date", value: "date" },
      ],
      items: [],
      placeholderImage: require("@/assets/default.jpg"),
    };
  },
  methods: {
    ...mapActions({
      getPartnerChats: "chat/getPartnerChats",
    }),
    onLoadData() {
      this.loading = true;
      this.getPartnerChats({
        _id: this._id,
        type: this.type,
      })
        .then((res) => {
          this.loading = false;
          this.items = res.map((r) => {
            let item = { ...r };
            item.participants = item.participants.filter(
              (ip) => ip._id != this._id
            );
            return item;
          });
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.error = error.response.data.message;
        });
    },
    goToChatRoom(item) {
      console.log(this.$route.params.id, item._id, item);
      if (this.$route.params.id != item._id) {
        this.$router.replace({
          params: { id: item._id },
        });
        this.onClose();
      }
    },
    onClose() {
      this.loading = false;
      this.error = null;
      this.onCloseDialog();
    },
    onChange() {
      this.error = null;
    },
    convertTZ(date, tzString) {
      if (!date) return "";
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: tzString ? tzString : "America/New_York",
          }
        )
      );
    },
    formatDate(dateStr) {
      var date = new Date(dateStr);
      return dateFormat(this.convertTZ(date, ""), "mm/dd/yyyy hh:MM TT");
    },
  },
  mounted() {
    console.log(this._id, this.type);
    this.onLoadData();
  },
};
</script>
<style>
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
.notranslate {
  transform: none !important;
}
</style>
